import { Tooltip } from 'antd'
import { useState } from 'react'
import EmplacementPicking from '../../../assets/icons/EmplacementPicking'
import EmplacementPrep from '../../../assets/icons/EmplacementPrep'
import EmplacementReception from '../../../assets/icons/EmplacementReception'
import EmplacementStandard from '../../../assets/icons/EmplacementStandard'
import { LocationStock } from '../../../models'
import EmplacementTooltip from '../Tooltip/EmplacementTooltip'

export enum EmplacementType {
  STANDARD = 'standard',
  PREPARATION = 'preparation',
  PICKING = 'picking',
  RECEPTION = 'reception',
}

interface EmplacementRenderProps {
  type: string
  current?: LocationStock
  previous?: LocationStock
  isHover?: boolean
}

const EmplacementRender = (props: EmplacementRenderProps) => {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)
  const { current, previous } = props
  switch (props.type) {
    case EmplacementType.STANDARD:
      return (
        <Tooltip
          title={<EmplacementTooltip current={current} previous={previous} />}
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
          placement="bottom"
        >
          <EmplacementStandard
            color={props.isHover ? '#FFF' : '#0189E3'}
            fill={props.isHover ? '#8B9FC7' : '#FFF'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        </Tooltip>
      )
    case EmplacementType.PREPARATION:
      return (
        <Tooltip
          title={<EmplacementTooltip current={current} previous={previous} />}
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
          placement="bottom"
        >
          <EmplacementPrep
            color={props.isHover ? '#FFF' : '#0189E3'}
            fill={props.isHover ? '#8B9FC7' : 'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        </Tooltip>
      )
    case EmplacementType.PICKING:
      return (
        <Tooltip
          title={<EmplacementTooltip current={current} previous={previous} />}
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
          placement="bottom"
        >
          <EmplacementPicking
            color={props.isHover ? '#FFF' : '#0189E3'}
            fill={props.isHover ? '#8B9FC7' : 'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        </Tooltip>
      )
    case EmplacementType.RECEPTION:
      return (
        <Tooltip
          title={<EmplacementTooltip current={current} previous={previous} />}
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
          placement="bottom"
        >
          <EmplacementReception
            color={props.isHover ? '#FFF' : '#0189E3'}
            fill={props.isHover ? '#8B9FC7' : 'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        </Tooltip>
      )
    default:
      return (
        <Tooltip
          title={<EmplacementTooltip current={current} previous={previous} />}
          color="#FFFFFF"
          overlayInnerStyle={{ borderRadius: 0 }}
          open={isShowTooltip}
          mouseEnterDelay={0.3}
          placement="bottom"
        >
          <EmplacementReception
            color={props.isHover ? '#FFF' : '#0189E3'}
            fill={props.isHover ? '#8B9FC7' : 'none'}
            onMouseLeave={() => {
              setIsShowTooltip(false)
            }}
            onMouseEnter={() => {
              setIsShowTooltip(true)
            }}
          />
        </Tooltip>
      )
  }
}

export default EmplacementRender
