import { t } from 'i18next'
import _, { cloneDeep } from 'lodash'
import moment from 'moment'
import { EmplacementType } from '../../components/Common/DataType'
import { default as Define, default as define } from '../../constants/define'
import { CommandeScene } from '../../enum/CommandeScene'
import {
  isStatusCommandeHigher,
  StatusCommande,
} from '../../enum/StatusCommande'
import { sourceType } from '../../enum/sourceType'
import {
  ComArticle,
  Commande,
  CommandeDataType,
  InfoActionType,
} from '../../models'
import { randomInt } from '../random'
import { EtaCommande } from '../../enum/EtaCommande'

export const makeTableValue = (commandeArray: Commande[]) => {
  let myCommandeList: CommandeDataType[] = []
  if (commandeArray) {
    commandeArray.forEach((e, index) => {
      myCommandeList.push(makeRowParentValue(e, index))
    })
  }
  return myCommandeList
}

function checkDuplicatePalletePicking(
  i: any,
  info_action: InfoActionType | undefined,
  sscc: string | undefined,
  source?: sourceType,
  status?: StatusCommande
) {
  return (
    i.info_action === InfoActionType.PICKING &&
    info_action === InfoActionType.PICKING &&
    sscc === i.sscc &&
    i.source === sourceType.AUTO &&
    source === sourceType.AUTO &&
    status &&
    isStatusCommandeHigher(status, StatusCommande.PickupCommande) &&
    i.status_code &&
    isStatusCommandeHigher(i.status_code, StatusCommande.PickupCommande) &&
    status === i.status_code
  )
}

function checkDuplicateNotHighestLevelLine(
  i: any,
  reference_id: string | undefined,
  lot: string | undefined,
  dluo: number | undefined,
  conditionement_level: number | undefined,
  status_code?: StatusCommande,
  is_manual_sscc?: boolean,
  sscc?: string
) {
  return (
    i.reference_id === reference_id &&
    i.lot === lot &&
    i.dluo === dluo &&
    conditionement_level === define.MAX_CDN_LEVEL - 1 &&
    i.status_code === status_code &&
    i.is_manual_sscc === is_manual_sscc &&
    i.sscc === sscc
    // ((i.is_manual_sscc && i.sscc === sscc) || !i.is_manual_sscc)
  )
}

function setSSCCDisplay(e: ComArticle) {
  return e?.info_action === InfoActionType.PICKING ||
    e?.conditionement_level === define.MAX_CDN_LEVEL ||
    (e?.conditionement_level === define.MAX_CDN_LEVEL - 1 &&
      e.is_manual_sscc) ||
    (e?.info_action === InfoActionType.REAPPRO_MISSION &&
      e?.conditionement_level === define.MAX_CDN_LEVEL - 1 &&
      e?.source === sourceType.AUTO)
    ? e.sscc
    : ''
}

const makeRowParentValue = (commande: Commande, index: number) => {
  //commande in edit or create status
  const editStatus = commande.scene === CommandeScene.EditScene
  let children: CommandeDataType[] = []
  // only show children if there are more than one ref
  if (commande.com_article) {
    // variable to save article that duplicate lot, dluo, reference
    let notHighestCdnAddedList: any[] = []
    cloneDeep(commande.com_article)
      .sort((a, b) => {
        return (
          (a.reference_number || '').localeCompare(b.reference_number || '') ||
          (a.conditionnement?.nom || '').localeCompare(
            b.conditionnement?.nom || ''
          )
        )
      })
      .forEach((e, index) => {
        if (
          editStatus ||
          (e.source === sourceType.AUTO && (!e.manquants || e.sscc))
        ) {
          // case carton duplicate lot, dluo, reference_id then group
          const {
            lot,
            dluo,
            reference_id,
            info_action,
            conditionement_level,
            reference_number,
            conditionnement,
            quantite,
            source,
            status_code,
            is_manual_sscc,
          } = e
          const sscc = setSSCCDisplay(e)
          const volumeOfCartonPicking =
            (conditionnement?.hauteur || 0) *
            (conditionnement?.largeur || 0) *
            (conditionnement?.longueur || 0) *
            (quantite || 0)
          const weightOfCartonPicking =
            (conditionnement?.poids_brut || 0) * (quantite || 0)
          const notHighestCdnAdded = notHighestCdnAddedList?.find(
            (i) =>
              checkDuplicateNotHighestLevelLine(
                i,
                reference_id,
                lot,
                dluo,
                conditionement_level,
                status_code,
                is_manual_sscc,
                sscc
              ) ||
              checkDuplicatePalletePicking(
                i,
                info_action,
                sscc,
                source,
                status_code
              )
          )
          if (notHighestCdnAdded && children?.[notHighestCdnAdded.position]) {
            let currentValue = cloneDeep(
              children?.[notHighestCdnAdded.position]
            )
            currentValue.totalPiece += e.total_pieses || 0
            delete currentValue.entre_time
            if (e.stock?.cur_location_id !== currentValue.current_location_id) {
              delete currentValue.emplacement
            }
            if (
              notHighestCdnAddedList?.find((i) =>
                checkDuplicatePalletePicking(
                  i,
                  info_action,
                  sscc,
                  source,
                  status_code
                )
              )
            ) {
              notHighestCdnAdded.produits = _.uniq([
                ...notHighestCdnAdded.produits,
                reference_number,
              ])
              notHighestCdnAdded.listLot = _.uniq([
                ...notHighestCdnAdded.listLot,
                lot,
              ])
              currentValue.produits = notHighestCdnAdded.produits
              currentValue.listLot = notHighestCdnAdded.listLot
              currentValue.niveau = define.MAX_CDN_LEVEL
              currentValue.qte = 1
              currentValue.sousqte += e.quantite || 0
              if (currentValue.volumeCartonPicking) {
                currentValue.volumeCartonPicking += volumeOfCartonPicking
              }
              if (currentValue.weightCartonPicking) {
                currentValue.weightCartonPicking += weightOfCartonPicking
              }
              currentValue.isPalletePicking = true
              currentValue.conditionnement = 'Palette picking'
              const childrenOfCartonPicking = currentValue.children
              if (
                childrenOfCartonPicking?.length &&
                childrenOfCartonPicking?.length > 0
              ) {
                // if there was a carton picking line with the same sscc, don't add it
                const duplicateCartonPickingIndex =
                  childrenOfCartonPicking.findIndex(
                    (i) => i.sscc === e.prev_sscc
                  )
                if (duplicateCartonPickingIndex < 0) {
                  childrenOfCartonPicking?.push(
                    makeRowCartonPickingValue(commande, e)
                  )
                }
              }
            } else {
              currentValue.conditionnement = 'Palette'
              currentValue.qte += e.quantite || 0
              currentValue.chargement = ''
            }
            // update value of line that was duplicated
            children?.splice(notHighestCdnAdded.position, 1, currentValue)
          } else {
            notHighestCdnAddedList.push({
              ...e,
              sscc,
              position: children?.length,
              produits: [reference_number],
              listLot: [lot],
              volumeCartonPicking: volumeOfCartonPicking,
              weightCartonPicking: weightOfCartonPicking,
            })
            e.listLot = lot ? [lot] : []
            e.produits = reference_number ? [reference_number] : []
            e.volumeCartonPicking = volumeOfCartonPicking
            e.weightCartonPicking = weightOfCartonPicking
            children?.push(makeRowChildValue(commande, e, index))
          }
        }
      })
  }
  //value of commande has only 1 line with sscc
  const onlyChildWithSSCCValue = commande?.com_article?.find(
    (el) =>
      (el.sscc && el.source === sourceType.AUTO) ||
      (el.is_manual_sscc &&
        ((el.ruptures_check_lot === 0 && el.source === sourceType.AUTO) ||
          (el.ruptures_check_lot === 1 && el.source === sourceType.MANUAL)))
  )

  const isError = !!commande?.com_article?.find(
    (i) => i?.manquants && i.manquants > 0
  )

  let filterAutoLines = commande.com_article?.filter(
    (item) => item.source === sourceType.AUTO
  )

  let articlesToCheck = editStatus ? commande.com_article : filterAutoLines
  let isAllLinesHaveSameRefNumber =
    articlesToCheck?.length > 0 &&
    articlesToCheck?.every(
      (item) =>
        item.reference_number === commande.com_article[0].reference_number
    )

  let countProduit = editStatus
    ? _.uniq(commande.com_article?.map((item) => item.reference_number))
    : _.uniq(filterAutoLines?.map((item) => item.reference_number))

  let filterAutoLinesHaveConditionnement = filterAutoLines?.filter(
    (item) => !!item.conditionement_level
  )

  let isAllBlocageEntree = articlesToCheck?.every(
    (el) => !!el.reference_product?.blocage_entree
  )
  let isAllBlocageSortie = articlesToCheck?.every(
    (el) => !!el.reference_product?.blocage_sortie
  )
  let atLeastOneLineBloc = articlesToCheck?.find(
    (el) =>
      !!el.reference_product?.blocage_entree ||
      !!el.reference_product?.blocage_sortie
  )
  let atLeastOneLineNotBloc = articlesToCheck?.find(
    (el) =>
      !el.reference_product?.blocage_entree &&
      !el.reference_product?.blocage_sortie
  )

  const displayMainLine =
    children?.filter((el) => el.source === sourceType.AUTO).length === 1 &&
    children.filter((el) => el.manquants).length === 0

  let sousQte = 0
  //calc main line sous qte (carton)
  if (children) {
    children.forEach((item) => {
      if (item.niveau === Define.MAX_CDN_LEVEL - 1 && item.qte)
        sousQte += item.qte
      if (item.niveau === Define.MAX_CDN_LEVEL && item.qte && item.sousqte)
        sousQte += item.qte * item.sousqte
    })
  }
  const countCdn = _.uniq(children?.map((item) => item.niveau)).length
  const listLot = _.uniq(
    commande.com_article
      ?.filter((item) => item.lot)
      ?.map((item) => item.lot || '')
  )

  let value: CommandeDataType = {
    key: commande.id || '',
    detail: '',
    select: true,
    id: commande.id || '',
    mouvement: 'SO',
    type: '60',
    hasManquants: isError,
    produit: isAllLinesHaveSameRefNumber ? children[0]?.produit || '' : '',
    conditionnement: countCdn > 0 ? children?.[0]?.conditionnement || '' : '',
    qte: children.reduce(
      (total, item) =>
        total + (item.niveau === define.MAX_CDN_LEVEL ? item.qte : 0),
      0
    ),
    sousqte: sousQte,
    totalPiece: editStatus
      ? commande.com_article?.reduce((sum, object) => {
          return sum + (object?.total_pieses || 0)
        }, 0) || 0
      : commande.com_article
          ?.filter(
            (el) => el.sscc && !el.manquants && el.source === sourceType.AUTO
          )
          .reduce((sum, object) => {
            return sum + (object?.total_pieses || 0)
          }, 0) || 0,
    sscc: '',
    dluo: '',
    bl: '',
    commande: commande.nom || '',
    chrono: commande.chrono || '',

    creation: commande?.created_at
      ? moment(Number(commande?.created_at) * 1000).format(t('time-format'))
      : '',
    modification: commande?.updated_at
      ? moment(Number(commande?.updated_at) * 1000).format(t('time-format'))
      : '',
    validation: commande?.loading_validation_time
      ? moment(commande?.loading_validation_time * 1000).format(
          t('time-format')
        )
      : '',
    validateBy: commande?.loading_validation_by || '',
    created_by: commande?.created_by,
    modification_by: commande?.modification_by || '',
    current_location: displayMainLine
      ? {
          allee: onlyChildWithSSCCValue?.stock?.cur_location_aisle,
          zone: onlyChildWithSSCCValue?.stock?.cur_zone_code,
          travee: onlyChildWithSSCCValue?.stock?.cur_location_bay,
          niveau:
            onlyChildWithSSCCValue?.stock?.cur_level === -1
              ? '0'
              : onlyChildWithSSCCValue?.stock?.cur_level,
          position:
            onlyChildWithSSCCValue?.stock?.cur_location_code === '0'
              ? '0'
              : onlyChildWithSSCCValue?.stock?.cur_location_code?.[0],
        }
      : undefined,

    previous_location: displayMainLine
      ? {
          allee: onlyChildWithSSCCValue?.stock?.pre_location_aisle,
          zone: onlyChildWithSSCCValue?.stock?.pre_zone_code,
          travee: onlyChildWithSSCCValue?.stock?.pre_location_bay,
          niveau:
            onlyChildWithSSCCValue?.stock?.cur_level === -1
              ? '0'
              : onlyChildWithSSCCValue?.stock?.cur_level,
          position:
            onlyChildWithSSCCValue?.stock?.pre_location_code === '0'
              ? '0'
              : onlyChildWithSSCCValue?.stock?.pre_location_code?.[0],
        }
      : undefined,

    destinataire: commande.destinataire
      ? `${commande.destinataire.code}_${commande.destinataire.nom}`
      : '',
    destinataire_id: commande.destinataire?.id,
    des_addresse_id: commande.des_addresse_id,
    des_contact_id: commande.des_contact_id,
    transporteur: commande.transporteur
      ? `${commande.transporteur.code}_${commande.transporteur.nom}`
      : '',
    transporteur_id: commande.transporteur_id,
    trans_addresse_id: commande.tran_addresse_id,
    trans_contact_id: commande.tran_contact_id,
    chargement: commande.chargement_at
      ? moment((commande?.chargement_at || 0) * 1000).format(t('date-format'))
      : '',
    eta: commande.eta,
    is_manual_sscc: onlyChildWithSSCCValue?.is_manual_sscc,
    // @ts-ignore
    produits: isAllLinesHaveSameRefNumber ? undefined : countProduit,
    countCdn: countCdn > 1 ? countCdn : undefined,
    niveau:
      countCdn > 1
        ? filterAutoLinesHaveConditionnement[0]?.conditionement_level || 0
        : children[0]?.niveau,
    blocage_entree: isAllBlocageEntree,
    blocage_sortie: isAllBlocageSortie,
    isDisplayMainLineStatut: !!atLeastOneLineBloc && !!atLeastOneLineNotBloc,
    listLot,
    lineLevel: 1,
    children,
  }

  if (
    children?.length &&
    children.every((item) => item.eta === EtaCommande.MissionDone)
  ) {
    value.eta = EtaCommande.MissionDone
  }

  return value
}

const makeRowChildValue = (
  commande: Commande,
  production: ComArticle,
  lineIndex: number
) => {
  const isPalletePicking =
    production.status_code &&
    isStatusCommandeHigher(
      production.status_code,
      StatusCommande.PickupCommande
    ) &&
    production.info_action === InfoActionType.PICKING &&
    !!production.sscc
  const value: CommandeDataType = {
    key: `${production.id}-${lineIndex}` || randomInt(20),
    id: production.id || '',
    detail: '',
    select: true,
    mouvement: 'SO',
    type: '60',

    produit: production?.reference_number || '',
    produits: production?.produits || [],
    conditionnement: isPalletePicking
      ? 'Palette picking'
      : production?.conditionnement?.nom || '',
    qte: isPalletePicking ? 1 : production?.quantite || 0,
    sousqte:
      (isPalletePicking ? production?.quantite : production?.sous_qte) || 0,
    totalPiece: production?.total_pieses || 0,
    sscc: setSSCCDisplay(production),
    dluo: isPalletePicking
      ? ''
      : production?.dluo
      ? moment(Number(production?.dluo) * 1000).format(t('date-format'))
      : '',
    bl: production.stock?.loading_bl || '',
    commande: commande.nom || '',
    creation: '',
    modification: '',
    validation: production.loading_validation_at
      ? moment(Number(production.loading_validation_at) * 1000).format(
          t('time-format')
        )
      : '',
    created_by: production.created_by,
    modification_by: production.modification_by,
    validateBy: production.loading_validation_by,
    volume_qte: production?.volume_qte,
    volumeCartonPicking: production?.volumeCartonPicking,
    weightCartonPicking: production?.weightCartonPicking,
    poids_qte: production?.poids_qte,
    volume_sousqte: production?.volume_sousqte,
    poids_sousqte: production?.poids_sousqte,
    emplacement: production.stock ? EmplacementType.STANDARD : undefined,
    current_location_id: production.stock?.cur_location_id,
    current_location: production
      ? {
          allee: production.stock?.cur_location_aisle,
          zone: production.stock?.cur_zone_code,
          travee: production.stock?.cur_location_bay,
          niveau:
            production.stock?.cur_level === -1 ||
            production.stock?.cur_location_code?.[1] === '0'
              ? '0'
              : production.stock?.cur_level,
          position:
            production.stock?.cur_location_code === '0'
              ? '0'
              : production.stock?.cur_location_code?.[0],
        }
      : undefined,
    previous_location: production
      ? {
          allee: production.stock?.pre_location_aisle,
          zone: production.stock?.pre_zone_code,
          travee: production.stock?.pre_location_bay,
          niveau:
            production.stock?.pre_level === -1 ||
            production.stock?.cur_location_code?.[1] === '0'
              ? '0'
              : production.stock?.pre_level,
          position:
            production.stock?.pre_location_code === '0'
              ? '0'
              : production.stock?.pre_location_code?.[0],
        }
      : undefined,
    destinataire: commande.destinataire
      ? `${commande.destinataire.code}_${commande.destinataire.nom}`
      : '',
    destinataire_id: commande.destinataire_id,
    des_addresse_id: commande.des_addresse_id,
    des_contact_id: commande.des_contact_id,
    transporteur: commande.transporteur
      ? `${commande.transporteur.code}_${commande.transporteur.nom}`
      : '',
    transporteur_id: commande.transporteur_id,
    trans_addresse_id: commande.tran_addresse_id,
    trans_contact_id: commande.tran_contact_id,
    chargement: commande.chargement_at
      ? moment((commande?.chargement_at || 0) * 1000).format(t('date-format'))
      : '',
    fabrication: isPalletePicking
      ? ''
      : production.stock?.create_at
      ? moment((production.stock?.create_at || 0) * 1000).format(
          t('time-format')
        )
      : '',
    entre_time: isPalletePicking
      ? ''
      : production?.stock?.create_at
      ? moment(Number(production.stock.create_at) * 1000).format(
          t('time-format')
        )
      : '',
    status_code: production.status_code,
    is_manual_sscc: production.is_manual_sscc,
    isDetailLine: true,
    chrono: production.chrono || '',
    blocage_entree: production.reference_product?.blocage_entree,
    blocage_sortie: production.reference_product?.blocage_sortie,
    pre_statut: production.reference_product?.pre_block_status,
    niveau: isPalletePicking
      ? define.MAX_CDN_LEVEL
      : production.conditionement_level,
    isPalletePicking,
    listLot: production?.listLot || [],
    source: production?.source,
    manquants: production.manquants,
    stock: production.stock,
    eta: production.eta,
    lineLevel: 2,
  }
  // detail line only have children line if it's pallete picking
  if (isPalletePicking) {
    value.children = [makeRowCartonPickingValue(commande, production)]
  }
  return value
}

// this line will show as pallete picking's children lines
const makeRowCartonPickingValue = (
  commande: Commande,
  production: ComArticle
) => {
  const { conditionnement } = production
  const value: CommandeDataType = {
    key: production.id || randomInt(20),
    id: production.id || '',
    detail: '',
    select: true,
    mouvement: 'SO',
    type: '60',

    produit: production?.reference_number || '',
    conditionnement: 'Carton',
    qte: production?.quantite || 0,
    sousqte: production?.sous_qte || 0,
    totalPiece: production?.total_pieses || 0,
    sscc: production.prev_sscc,
    dluo: production?.dluo
      ? moment(Number(production?.dluo) * 1000).format(t('date-format'))
      : '',
    bl: production.stock?.loading_bl || '',
    commande: commande.nom || '',
    creation: '',
    modification: '',
    validation: production.loading_validation_at
      ? moment(Number(production.loading_validation_at) * 1000).format(
          t('time-format')
        )
      : '',
    created_by: production.created_by,
    modification_by: production.modification_by,
    validateBy: production.loading_validation_by,
    volume_qte: production?.volume_qte,
    poids_qte: production?.poids_qte,
    volume_sousqte: production?.volume_sousqte,
    poids_sousqte: production?.poids_sousqte,
    emplacement: production.stock ? EmplacementType.STANDARD : undefined,
    current_location_id: production.stock?.cur_location_id,
    current_location: production
      ? {
          allee: production.stock?.cur_location_aisle,
          zone: production.stock?.cur_zone_code,
          travee: production.stock?.cur_location_bay,
          niveau:
            production.stock?.cur_level === -1
              ? '0'
              : production.stock?.cur_level,
          position:
            production.stock?.cur_location_code === '0'
              ? '0'
              : production.stock?.cur_location_code?.[0],
        }
      : undefined,
    previous_location: production
      ? {
          allee: production.stock?.pre_location_aisle,
          zone: production.stock?.pre_zone_code,
          travee: production.stock?.pre_location_bay,
          niveau:
            production.stock?.pre_level === -1
              ? '0'
              : production.stock?.pre_level,
          position:
            production.stock?.pre_location_code === '0'
              ? '0'
              : production.stock?.pre_location_code?.[0],
        }
      : undefined,
    destinataire: commande.destinataire
      ? `${commande.destinataire.code}_${commande.destinataire.nom}`
      : '',
    destinataire_id: commande.destinataire_id,
    des_addresse_id: commande.des_addresse_id,
    des_contact_id: commande.des_contact_id,
    transporteur: commande.transporteur
      ? `${commande.transporteur.code}_${commande.transporteur.nom}`
      : '',
    transporteur_id: commande.transporteur_id,
    trans_addresse_id: commande.tran_addresse_id,
    trans_contact_id: commande.tran_contact_id,
    chargement: commande.chargement_at
      ? moment((commande?.chargement_at || 0) * 1000).format(t('date-format'))
      : '',
    fabrication: production?.stock?.create_at
      ? moment(Number(production.stock.create_at) * 1000).format(
          t('time-format')
        )
      : '',
    entre_time: production?.stock?.create_at
      ? moment(Number(production.stock.create_at) * 1000).format(
          t('time-format')
        )
      : '',
    status_code: production.status_code,
    is_manual_sscc: production.is_manual_sscc,
    isDetailLine: true,
    chrono: '',
    blocage_entree: production.reference_product?.blocage_entree,
    blocage_sortie: production.reference_product?.blocage_sortie,
    pre_statut: production.reference_product?.pre_block_status,
    niveau: 2,
    listLot: [production?.lot || ''],
    source: production?.source,
    manquants: production.manquants,
    stock: production.stock,
    isPalletePicking: true,
    volumeCartonPicking:
      (conditionnement?.hauteur || 0) *
      (conditionnement?.largeur || 0) *
      (conditionnement?.longueur || 0),
    weightCartonPicking: conditionnement?.poids_brut || 0,
    eta: production.eta,
    lineLevel: 3,
  }
  return value
}
