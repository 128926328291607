interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function EmplacementPicking(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.8706 19.758V14.2872L22.1313 12.7743C22.2367 12.6478 22.2573 12.4708 22.1836 12.3234L19.6365 7.22929C19.5169 6.99014 19.2056 6.92502 19.0002 7.09619L17.1737 8.61825C17.1113 8.67028 17.0675 8.73746 17.0442 8.81064H9.91516L8.60159 7.23436C8.41317 7.00825 8.05571 7.04498 7.9172 7.30468L5.0488 12.6829C4.95633 12.8563 4.99826 13.0708 5.14922 13.1966L6.97569 14.7187C7.03358 14.7669 7.09989 14.7964 7.16795 14.8085V19.758C7.16795 19.987 7.35359 20.1726 7.58258 20.1726H20.4559C20.6849 20.1726 20.8706 19.987 20.8706 19.758ZM9.23405 13.8099L10.5609 10.4928H17.4629L18.7898 13.8099H9.23405Z"
        fill={color}
      />
    </svg>
  )
}
