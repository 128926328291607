interface IconInterface {
  color: string
  opacity?: number
  fill?: string
  onMouseLeave: () => void
  onMouseEnter: () => void
}

export default function EmplacementStandard(props: IconInterface) {
  const { color, opacity, fill, onMouseEnter, onMouseLeave } = props
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 27 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      opacity={opacity}
      display="block"
    >
      <circle cx="13.5" cy="13.5" r="12.75" stroke={color} stroke-width="1.5" />
      <path
        d="M8.46838 6.97168H18.6112L21.2706 13.0552V19.6728H5.74707V13.0552L8.46838 6.97168Z"
        fill={color}
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.4663 6.68945V12.6895"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="square"
      />
      <path
        d="M21 13L6 13"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="square"
      />
    </svg>
  )
}
