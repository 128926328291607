import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Checkbox, Empty, Pagination, Tooltip, message } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { t } from 'i18next'
import React, { Key, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../app/store'
import COMMANDE_ICON from '../../../assets/icons/Commande icone.svg'
import BLRender from '../../../components/Common/DataType/BLType'
import ConditionnementRender from '../../../components/Common/DataType/ConditionnementType'
import DatetimeUserRender from '../../../components/Common/DataType/DatetimeUserRender'
import EmplacementRender from '../../../components/Common/DataType/EmplacementType'
import EtatRender from '../../../components/Common/DataType/EtatType'
import MovementRender from '../../../components/Common/DataType/MovementType'
import ObjectRender from '../../../components/Common/DataType/ObjectType'
import QteRender from '../../../components/Common/DataType/QteType'
import SelectRender from '../../../components/Common/DataType/SelectType'
import StatutRender from '../../../components/Common/DataType/StatutType'
import SortColumn, {
  getDefaultSorting,
} from '../../../components/Common/SortColumn'
import LotTooltip from '../../../components/Common/Tooltip/LotTooltip'
import MovementHeaderTooltip from '../../../components/Common/Tooltip/MovementHeaderTooltip'
import ProduitTooltip from '../../../components/Common/Tooltip/ProduitTooltip'
import StatutTitleTooltip from '../../../components/Common/Tooltip/StatutHeaderTooltip'
import Define from '../../../constants/define'
import { Page, PagingEnum } from '../../../enum'
import { EtaCommande } from '../../../enum/EtaCommande'
import { useRenderDataByInterval } from '../../../hook/useRenderDataByInterval'
import useScroll from '../../../hook/useScroll'
import { CommandeDataType, CommandeResponse } from '../../../models'
import {
  getAllCommande,
  getTotalCommande,
} from '../../../redux/reducers/commandeSlice'
import { getDestinataireByIdElement } from '../../../redux/reducers/destinataireSlice'
import { getTransporteurByIdElement } from '../../../redux/reducers/transporteurSlice'
import { convertRemToPixels, getCurrentFiltersText } from '../../../utils'
import { makeTableValue } from '../../../utils/commande/makeTableValue'
import ActionRow from '../components/ActionRow/ActionRow'
import ResearchBar from '../components/ResearchBar/ResearchBar'
import { ModalName } from '../modal/customModalSlice'
import { redirectToCmdScreen } from '../modal/type/commande/func'
import './Commande.scss'
import OpenPickingLine from '../../../assets/icons/OpenPickingLine'
import ClosePickingLine from '../../../assets/icons/ClosePickingLine'

const Commande = () => {
  const navigate = useNavigate()
  const { choosingClient, choosingCompany, choosingWarehouse } = useSelector(
    (state: RootState) => state.account
  )
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  const [messageApi, contextHolder] = message.useMessage()

  const [isShowLess, setIsShowLess] = useState<boolean>(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [rowHover, setRowHover] = useState<React.Key>()
  const { currentListCommande, totalCommande } = useSelector(
    (state: RootState) => state.commande
  )
  const [isCollapsible, setIsCollapsible] = useState<boolean>(false)

  const dispatch = useDispatch()

  const searchCommande = useCallback(
    (values: any) => {
      const searchParams = new URLSearchParams(values)
      const queryString = searchParams.toString()
      navigate(
        `/gestion-de-stock/commande?page-index=1&page-size=${pageSize}&${queryString}`
      )
      if (Number(pageIndex) === 1) {
        dispatch(getAllCommande({ isRealTime: false }))
      }
      dispatch(getTotalCommande())
    },
    [dispatch, navigate, pageIndex, pageSize]
  )

  const mapFieldByTableCol = {
    etat: 'eta',
    bl: 'bl',
    qte: 'qte',
    sousqte: 'sous_qte',
    totalPiece: 'total_pieses',
    produit: 'reference_number',
    commande: 'nom',
    destinataire: 'addressee',
    transporteur: 'transporter',
    chargement: 'chargement_at',
    creation: 'created_at',
    modification: 'updated_at',
    validation: 'validation_time',
    chrono: 'chrono',
  }

  const handleTableChange = async (p: any, f: any, sorter: any) => {
    // @ts-ignore
    const fieldSort = mapFieldByTableCol[sorter.field]
    if (fieldSort) {
      navigate(
        `/gestion-de-stock/commande?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText(
          ['sort', 'direction']
        )}&sort=${fieldSort}&direction=${sorter.order}`
      )
      dispatch(getAllCommande({ isRealTime: false }))
    }
  }
  useRenderDataByInterval({
    page: Page.COMMANDES,
    intervalTime: Define.ONE_MINUTE,
    dispatch,
    pageIndex,
    pageSize,
    navigate,
  })

  const columns: ColumnsType<CommandeDataType> = [
    {
      title: <div className="text-[#808080]">Détail</div>,
      dataIndex: 'detail',
      key: 'detail',
      width: 80,
      fixed: 'left',
    },
    {
      title: () => (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              let fullArray: Array<Key> = []
              data.forEach((item) => {
                fullArray.push(item.key)
                item.children?.map((i) => fullArray.push(i.key))
              })
              setSelectedRowKeys(fullArray)
            } else {
              setSelectedRowKeys([])
            }
          }}
          className="checkbox-gray flex justify-center"
        />
      ),
      dataIndex: 'select',
      key: 'select',
      width: 80,
      render: (_, row) => {
        return (
          <SelectRender
            isMainLine={!!row.children}
            isCheck={selectedRowKeys.includes(row.key)}
            onChangeChecked={(e) => {
              if (e.target.checked) {
                setSelectedRowKeys([...selectedRowKeys, row.key])
              } else {
                setSelectedRowKeys(
                  [...selectedRowKeys].filter((i) => i !== row.key)
                )
              }
            }}
            screenColor="gray"
          />
        )
      },
      fixed: 'left',
    },
    {
      title: () => (
        <Tooltip
          title={<MovementHeaderTooltip />}
          placement="bottom"
          color={'white'}
          overlayClassName="movement-header-tooltip"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.2}
          overlayInnerStyle={{
            position: 'absolute',
            top: 0,
            borderRadius: 0,
            height: 'auto',
            left: -105,
          }}
        >
          Mouvement
        </Tooltip>
      ),
      dataIndex: 'mouvement',
      key: 'mouvement',
      width: 280,
      className: 'first-column',
      render: (_, row) => {
        return (
          <MovementRender
            movement={row.mouvement}
            type={row.type}
            isChildren={!!row.children}
          />
        )
      },
    },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      width: 100,
      defaultSortOrder: getDefaultSorting('eta'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <EtatRender
            type={row.eta || EtaCommande.CreatedCommande}
            isMainLine={!!row.children}
            isHover={row.key === rowHover}
            screen={ModalName.COMMANDE}
          />
        )
      },
    },
    {
      title: 'Produit',
      dataIndex: 'produit',
      key: 'produit',
      width: 200,
      render: (_, row) => {
        return (
          <>
            {row.produits && row.produits?.length > 1 ? (
              <ProduitTooltip
                showTooltip={!row.children}
                countProduit={row.produits?.length}
                listProduit={row.produits}
              />
            ) : row.produit ? (
              <div
                className={`text-secondary hover-text truncate ${
                  row.children ? 'font-semibold' : 'font-medium'
                } `}
              >
                {row.produit}
              </div>
            ) : (
              <div>-</div>
            )}
          </>
        )
      },
    },
    {
      title: 'Conditionnement',
      dataIndex: 'conditionnement',
      key: 'conditionnement',
      width: 170,
      render: (_, row) => {
        return (
          <ConditionnementRender
            type={row.conditionnement || ''}
            countCdn={row.countCdn}
            isMainLine={
              row.children && row.children.length > 0 && !row.isPalletePicking
            }
            niveau={row.niveau}
            isHover={row.key === rowHover}
          />
        )
      },
    },
    {
      title: 'Qte',
      dataIndex: 'qte',
      key: 'qte',
      width: 100,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => a.qte - b.qte,
      render: (_, row) => {
        return (
          <QteRender
            notShowTooltip={
              row.children && row.children?.length > 0 && row.lineLevel === 1
            }
            data={row.qte}
            volume={
              row.isPalletePicking ? row.volumeCartonPicking : row.volume_qte
            }
            poids={
              row.isPalletePicking ? row.weightCartonPicking : row.poids_qte
            }
          />
        )
      },
    },
    {
      title: 'Sous-Qte',
      dataIndex: 'sousqte',
      key: 'sousqte',
      width: 140,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => a.sousqte - b.sousqte,
      render: (_, row) => {
        return (
          <QteRender
            notShowTooltip={
              (row.children &&
                row.children?.length > 0 &&
                row.lineLevel === 1) ||
              (row.lineLevel === 2 && row.isPalletePicking)
            }
            data={row.sousqte}
            volume={row.volume_sousqte}
            poids={row.poids_sousqte}
          />
        )
      },
    },
    {
      title: 'Total pièce',
      dataIndex: 'totalPiece',
      key: 'totalPiece',
      width: 200,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: (a, b) => a.totalPiece - b.totalPiece,
      render: (_, row) => {
        return <div>{row.totalPiece || '-'}</div>
      },
    },
    {
      title: 'Palette',
      dataIndex: 'palette',
      key: 'palette',
      width: 210,
      render: (_, row) => {
        return (
          <>
            {row.sscc ? (
              <div className={`text-secondary hover-text`}>
                {row.sscc || '-'}
              </div>
            ) : (
              <>-</>
            )}
          </>
        )
      },
    },
    {
      title: 'DLUO',
      dataIndex: 'dluo',
      key: 'dluo',
      width: 120,
      render: (_, row) => {
        return (
          <>
            {row.dluo ? (
              <div className="text-secondary hover-text">{row.dluo || '-'}</div>
            ) : (
              <>-</>
            )}
          </>
        )
      },
    },
    {
      title: () => <StatutTitleTooltip />,
      dataIndex: 'statut',
      key: 'statut',
      width: 80,
      render: (_, row) => {
        return (
          <>
            {row.isDisplayMainLineStatut || row.children?.length === 0 ? (
              '-'
            ) : (
              <StatutRender
                blocage_entree={row.blocage_entree}
                blocage_sortie={row.blocage_sortie}
                previousStatut={row.pre_statut}
                isHover={row.key === rowHover}
                isMainLine={!!row.children}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'Emplacement',
      dataIndex: 'emplacement',
      key: 'emplacement',
      width: 150,

      render: (_, row) =>
        row.emplacement ? (
          <EmplacementRender
            previous={
              row.isPalletePicking && row.lineLevel === 2
                ? undefined
                : row.previous_location
            }
            current={row.current_location}
            type={row.emplacement}
            isHover={row.key === rowHover}
          />
        ) : (
          <div>-</div>
        ),
    },
    {
      title: 'BL',
      dataIndex: 'bl',
      key: 'bl',
      width: 160,
      defaultSortOrder: getDefaultSorting('bl'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return <BLRender data={row.bl} />
      },
    },
    {
      title: 'Commandes',
      dataIndex: 'commande',
      key: 'commande',
      width: 160,
      defaultSortOrder: getDefaultSorting('nom'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <div
            className={`${!row.isDetailLine && `cursor-pointer`} `}
            onClick={() => {
              const dataCmd: CommandeResponse = dataInit.find(
                (el) => el.id === row.id
              ) as CommandeResponse

              const linkToRedirect = redirectToCmdScreen(
                dataCmd,
                dataCmd,
                pageIndex,
                pageSize
              )
              if (row.isDetailLine) return
              navigate(linkToRedirect)
            }}
          >
            <BLRender data={row.commande} />
          </div>
        )
      },
    },
    {
      title: 'Destinataire',
      dataIndex: 'destinataire',
      key: 'destinataire',
      width: 280,
      defaultSortOrder: getDefaultSorting('addressee'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        if (row.destinataire) {
          return (
            <div
              className="text-secondary font-semibold hover-text truncate"
              onClick={() => {
                dispatch(
                  getDestinataireByIdElement({
                    id: row.destinataire_id,
                    id_add: row.des_addresse_id,
                    id_contact: row.des_contact_id,
                  })
                )
                navigate(
                  `/gestion-de-stock/commande/destinataire/only-view/${
                    row.destinataire_id
                  }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
                )
              }}
            >
              <ObjectRender value={row.destinataire} type={'destinataire'} />
            </div>
          )
        } else {
          return <div>-</div>
        }
      },
    },
    {
      title: 'Transporteur',
      dataIndex: 'transporteur',
      key: 'transport',
      width: 280,
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      defaultSortOrder: getDefaultSorting('transporter'),
      render: (_, row) => {
        if (row.transporteur) {
          return (
            <div
              className="text-secondary font-semibold hover-text truncate"
              onClick={() => {
                dispatch(
                  getTransporteurByIdElement({
                    id: row.transporteur_id,
                    id_add: row.trans_addresse_id,
                    id_contact: row.trans_contact_id,
                  })
                )
                navigate(
                  `/gestion-de-stock/commande/transporteur/only-view/${
                    row.transporteur_id
                  }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
                )
              }}
            >
              <ObjectRender value={row.transporteur} type={'transporteur'} />
            </div>
          )
        } else {
          return (
            <div className="text-secondary font-semibold hover-text">
              Enlèvement Client
            </div>
          )
        }
      },
    },
    {
      title: 'Chrono',
      dataIndex: 'chrono',
      key: 'chrono',
      width: 145,
      defaultSortOrder: getDefaultSorting('chrono'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        const isParent = Boolean(row.children)
        if (isParent) {
          if (row.chrono)
            return (
              <Link
                className="hover-text text-[#808080]"
                to={`/gestion-de-stock/commande/chrono/${
                  row.id
                }?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`}
              >
                {row.chrono}
              </Link>
            )
          return (
            <span className="text-[#808080] font-semibold hover-text">-</span>
          )
        } else {
          if (row.chrono)
            return (
              <Link
                className="hover-text text-[#808080]"
                to={`/gestion-de-stock/commande/chrono/sscc/${row.sscc}`}
              >
                {row.chrono}
              </Link>
            )
          return <span className="hover-text text-[#808080]">-</span>
        }
      },
    },
    {
      title: 'Chargement',
      dataIndex: 'chargement',
      key: 'chargement',
      width: 150,
      defaultSortOrder: getDefaultSorting('chargement_at'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return <div>{row.chargement || '-'}</div>
      },
    },
    {
      title: 'Entrée',
      dataIndex: 'entree',
      key: 'entree',
      width: 180,
      render: (_, row) => {
        return (
          <>
            {row.entre_time ? (
              <div className="text-secondary font-semibold hover-text">
                {row.entre_time}
              </div>
            ) : (
              <>-</>
            )}
          </>
        )
      },
    },
    {
      title: 'Lot',
      dataIndex: 'lot',
      key: 'lot',
      width: 210,
      render: (_, row) => {
        return <LotTooltip listLot={row.listLot} />
      },
    },
    {
      title: 'Fabrication',
      dataIndex: 'fabrication',
      key: 'fabrication',
      width: 170,
      render: (_, row) => {
        return <div>{row.fabrication || '-'}</div>
      },
    },
    {
      title: 'Libération',
      dataIndex: 'liberation',
      key: 'liberation',
      width: 120,
      render: (_, row) => {
        return <div>{row.liberation || '-'}</div>
      },
    },
    {
      title: 'Création',
      dataIndex: 'creation',
      key: 'creation',
      width: 170,
      defaultSortOrder: getDefaultSorting('created_at', 'ascend'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <DatetimeUserRender datetime={row.creation} user={row.created_by} />
        )
      },
    },
    {
      title: 'Modification',
      dataIndex: 'modification',
      key: 'modification',
      width: 170,
      defaultSortOrder: getDefaultSorting('updated_at'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <DatetimeUserRender
            datetime={row.modification}
            user={row.modification_by}
          />
        )
      },
    },
    {
      title: 'Validation',
      dataIndex: 'validation',
      key: 'validation',
      width: 170,
      defaultSortOrder: getDefaultSorting('validation_time'),
      sortIcon: ({ sortOrder }) => <SortColumn sortOrder={sortOrder} />,
      sorter: true,
      render: (_, row) => {
        return (
          <DatetimeUserRender datetime={row.validation} user={row.validateBy} />
        )
      },
    },
    {
      title: 'Tracking',
      dataIndex: 'tracking',
      key: 'tracking',
      width: 200,
      render: (_, row) => {
        return <div>-</div>
      },
    },
  ]

  const rowClassName = (record: any, index: number) => {
    if (!!record.children) {
      if (index % 2 === 0) return 'table-row-main-even'
      else return ' table-row-main-odd'
    } else return 'table-row-child'
  }

  const isChooseWarehouse =
    choosingClient && choosingWarehouse && choosingCompany

  const data = isChooseWarehouse
    ? makeTableValue(currentListCommande.commandeList)
    : []
  const dataInit = currentListCommande.commandeList

  const height =
    window.innerHeight - 240 - convertRemToPixels(isCollapsible ? 16 : 4)

  useScroll(data)

  function closeLine(record: CommandeDataType) {
    setExpandedRowKeys(expandedRowKeys.filter((item) => item !== record.key))
  }

  function openLine(
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    record: CommandeDataType
  ) {
    if (e.shiftKey || e.ctrlKey || record.isPalletePicking) {
      setExpandedRowKeys([...expandedRowKeys, record.key])
      if (expandedRowKeys.length >= 10) {
        messageApi.warning(
          'Attention : l’affichage de plus de Détails peut limiter vos performances'
        )
      }
    } else {
      setExpandedRowKeys([record.key])
    }
  }

  return (
    <div>
      {contextHolder}

      <ResearchBar
        screen={ModalName.COMMANDE}
        onSearch={searchCommande}
        listFilters={[
          'BL',
          'Libelle',
          'Destinataire',
          'Transporteur',
          'CommandeType',
          'CommandeMouvement',
          'CommandeEtat',
          'Chrono',
          'Debut',
          'Fin',
          'Reference',
          'Status',
          'Palette',
          'Lot',
          'Cdn',
          'Zone',
          'Allee',
          'Travee',
          'Niveau',
          'Type Cdn',
        ]}
        requiredToAdd={['client', 'company', 'warehouse']}
        requiredToFilters={['client', 'company', 'warehouse']}
        isCollapsible={isCollapsible}
        setIsCollapsible={setIsCollapsible}
        searchBarHeight={12}
        totalIn={isChooseWarehouse ? currentListCommande?.metadata?.total : 0}
        total={isChooseWarehouse ? totalCommande?.number : 0}
      />
      <div className="flex ml-10 justify-between">
        <div className="flex items-center">
          <img src={COMMANDE_ICON} alt="commande-icon" className="mr-3" />
          <div className="page-title-heading-1">COMMANDES</div>
        </div>
        <ActionRow
          isDisplayActionDropdown={selectedRowKeys.length >= 1}
          isDisableActionButton={
            !choosingClient && !choosingCompany && !choosingWarehouse
          }
        />
      </div>

      <div className="flex justify-center commandes">
        <Table
          virtual
          onChange={handleTableChange}
          showSorterTooltip={false}
          className="attendu-cmd-stock   table-wrapper overflow-y-auto relative text-[#EBEBEB]"
          columns={columns}
          pagination={false}
          scroll={{ x: 'scroll', y: height }}
          indentSize={0}
          loading={currentListCommande.loading}
          dataSource={data}
          rowClassName={rowClassName}
          locale={{
            emptyText: (
              <span>
                <Empty description={''} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <p className="text-center">
                  {isChooseWarehouse
                    ? t('noStockFound')
                    : t('notEnoughCondition')}
                </p>
              </span>
            ),
          }}
          expandable={{ expandedRowKeys }}
          expandIcon={({ expanded, onExpand, record }) =>
            // only show dropdown icon if only has children
            record.children !== undefined && record.children?.length > 0 ? (
              expanded ? (
                <>
                  {record.isPalletePicking ? (
                    <div
                      onClick={() => closeLine(record)}
                      className="flex items-center ml-1"
                    >
                      <OpenPickingLine />
                    </div>
                  ) : (
                    <UpOutlined onClick={() => closeLine(record)} />
                  )}
                </>
              ) : (
                <>
                  {record.isPalletePicking ? (
                    <div
                      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                        openLine(e, record)
                      }
                      className="flex items-center ml-1"
                    >
                      <ClosePickingLine />
                    </div>
                  ) : (
                    <DownOutlined
                      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
                        openLine(e, record)
                      }
                    />
                  )}
                </>
              )
            ) : (
              <></>
            )
          }
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: (e) => {
                setRowHover(record.key)
              },
              onMouseLeave: (e) => {
                setRowHover(undefined)
              },
            }
          }}
        />
      </div>
      {choosingClient &&
        choosingWarehouse &&
        choosingCompany &&
        currentListCommande.commandeList &&
        currentListCommande.commandeList.length !== 0 && (
          <div className="custom-paging-attendu-stock-commande-stock-commande">
            <Pagination
              className="custom-pagination"
              locale={{ items_per_page: '' }}
              current={Number(pageIndex)}
              total={currentListCommande?.metadata?.total}
              defaultPageSize={PagingEnum.DEFAULT_PAGE_SIZE}
              showLessItems={isShowLess}
              pageSizeOptions={[
                PagingEnum.DEFAULT_PAGE_SIZE,
                PagingEnum.PAGE_SIZE_1,
                PagingEnum.PAGE_SIZE_2,
              ]}
              showSizeChanger
              showTotal={(total, range) => {
                return `${range[0] >= 0 ? range[0] : 0}-${
                  range[1] >= 0 ? range[1] : 0
                } sur ${total}`
              }}
              onChange={(page, size) => {
                navigate(
                  `/gestion-de-stock/commande?page-index=${page}&page-size=${size}${getCurrentFiltersText()}`
                )
                setIsShowLess(() => {
                  return page > 4
                })
              }}
            />
          </div>
        )}
      <Outlet />
    </div>
  )
}

export default Commande
