import { CSSProperties } from 'react'
import { LocationStock } from '../../../models'

interface EmplacementTooltipProps {
  current?: LocationStock
  previous?: LocationStock
}

/**
 * The EmplacementTooltip will show tooltip when hovering button
 * @author [anhht]
 * @param {type, data, stype} ;
 * @version 0.1
 */
const EmplacementTooltip = (props: EmplacementTooltipProps) => {
  const { previous, current } = props
  return (
    <div className="m1-tooltip">
      <div>Zone : {current?.zone || '-'}</div>
      <div>Allée : {current?.allee || '-'}</div>
      <div>Travée : {current?.travee || '-'}</div>
      <div>Niveau : {current?.niveau || '-'}</div>
      <div>Position : {current?.position || '-'}</div>
      <hr />
      {previous ? (
        <>
          <div>Zone origine: {previous?.zone || '-'}</div>
          <div>Allée origine: {previous?.allee || '-'} </div>
          <div>Travée origine: {previous?.travee || '-'} </div>
          <div>Niveau origine: {previous?.niveau || '-'} </div>
          <div>Position origine: {previous?.position || '-'} </div>
        </>
      ) : null}
    </div>
  )
}

export default EmplacementTooltip
