import { Tooltip } from 'antd'
import React, { useState } from 'react'
import { renderDigitsByNumber } from '../../../utils'

export default function LotTooltip({ listLot }: { listLot?: string[] }) {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)

  if (!listLot || listLot.length === 0) {
    return <>-</>
  }

  if (listLot?.length === 1) {
    return (
      <div className="text-secondary hover-text">
        {renderDigitsByNumber(listLot?.[0], 11, true)}
      </div>
    )
  }

  return (
    <Tooltip
      title={<div className="m1-tooltip">{listLot?.join(', ')}</div>}
      color="#FFFFFF"
      overlayInnerStyle={{ borderRadius: 0 }}
      open={isShowTooltip}
      mouseEnterDelay={0.3}
    >
      <div
        onMouseLeave={() => {
          setIsShowTooltip(false)
        }}
        onMouseEnter={() => {
          setIsShowTooltip(true)
        }}
        className="rounded-full bg-[#0189E3] text-center mouvement-hover"
        style={{ height: 23, width: 23 }}
      >
        <div
          className="flex justify-center items-center"
          style={{ height: 23 }}
        >
          <div className="text-white mouvement-hover-text">
            {listLot?.length}
          </div>
        </div>
      </div>
    </Tooltip>
  )
}
