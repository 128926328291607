import { Tooltip } from 'antd'
import React, { useState } from 'react'

export default function ({
  countProduit,
  listProduit,
  showTooltip,
}: {
  countProduit: number
  listProduit?: string[]
  showTooltip?: boolean
}) {
  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false)

  if (!showTooltip) {
    return (
      <div
        className="rounded-full bg-[#0189E3] text-center mouvement-hover"
        style={{ height: 23, width: 23 }}
      >
        <div
          className="flex justify-center items-center"
          style={{ height: 23 }}
        >
          <div className="text-white mouvement-hover-text">{countProduit}</div>
        </div>
      </div>
    )
  }

  return (
    <Tooltip
      title={<div className="m1-tooltip">{listProduit?.join(', ')}</div>}
      color="#FFFFFF"
      overlayInnerStyle={{ borderRadius: 0 }}
      open={isShowTooltip}
      mouseEnterDelay={0.3}
    >
      <div
        onMouseLeave={() => {
          setIsShowTooltip(false)
        }}
        onMouseEnter={() => {
          setIsShowTooltip(true)
        }}
        className="rounded-full bg-[#0189E3] text-center mouvement-hover"
        style={{ height: 23, width: 23 }}
      >
        <div
          className="flex justify-center items-center"
          style={{ height: 23 }}
        >
          <div className="text-white mouvement-hover-text">{countProduit}</div>
        </div>
      </div>
    </Tooltip>
  )
}
